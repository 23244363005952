<template>
  <div>
    <div class="text-right mb-1">
      <b-button
        variant="primary"
        pill
        @click="createPatient"
        v-if="hasPermission('create_patient_category')"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Create</span>
      </b-button>
    </div>

    <b-card>
      <b-row class="mb-1" align-v="center">
        <b-col md="7">
          <div v-if="searchType">
            <b-form-group
              label="Passport Number"
              label-for="passport_number"
              class="w-50"
              v-if="searchType.value === 1"
            >
              <b-input-group>
                <template #prepend> </template>
              </b-input-group>
              <b-form-input
                id="passport_number"
                v-model="passport_number"
                placeholder="Passport #"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group label="Search Type" label-for="searchType">
            <v-select
              id="searchType"
              v-model="searchType"
              :options="searchTypes"
              placeholder="Search Type"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="patients"
        details-td-class="m-0 p-0"
        small
        v-if="hasPermission('read_patient')"
        :per-page="0"
      >
        <template #custom-foot>
          <b-tr variant="light">
            <b-td :colspan="fields.length">
              <span class="font-weight-bolder">
                Total Rows: {{ totalItems }}
              </span>
            </b-td>
          </b-tr>
        </template>
        <template #cell(serial)="row">
          {{ (currentPage - 1) * perPage + row.index + 1 }}
        </template>

        <!-- <template #cell(manage)="row">
          <b-button
            variant="info"
            pill
            size="sm"
            class="mr-1"
            @click="editPatient(row.item)"
            v-if="hasPermission('update_patient')"
            disabled
          >
            Edit
          </b-button>
          <b-button
            variant="danger"
            pill
            size="sm"
            @click="removePatient(row.item)"
            v-if="hasPermission('delete_patient')"
            disabled
          >
            Delete
          </b-button>
        </template> -->
      </b-table>
      <b-pagination
        size="md"
        :total-rows="totalItems"
        v-model="currentPage"
        :per-page="perPage"
      ></b-pagination>
      <!-- <span class="mt-1 bg1 float-right" >Total Rows: {{ rows }}</span> -->
    </b-card>
    <!-- <patient-create-modal
      @modalClosed="onModalClosed"
      :key="`create-${patientCreateModalCount}`"
    />
    <patient-edit-modal
      :patient="patient"
      @modalClosed="onModalClosed"
      :key="`edit-${patientEditModalCount}`"
    /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
// import PatientCreateModal from "@/components/admin/patient/PatientCreateModal.vue";
// import PatientEditModal from "@/components/admin/patient/PatientEditModal.vue";

export default {
  components: {
    // PatientCreateModal,
    // PatientEditModal
  },
  data() {
    return {
      fields: [
        { key: "serial", label: "S. No" },
        { key: "full_name", label: "Name" },
        // { key: "last_name", label: "Last Name" },
        { key: "email", label: "Email" },
        { key: "mobile", label: "Mobile" },
        { key: "nationality", label: "Nationality" },
        { key: "passport_number", label: "Passport #" },
        { key: "user", label: "User ID" },
        { key: "manage", label: "Action" },
      ],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      patients: [],
      patient: null,
      PatientCreateModal: 0,
      PatientEditModal: 0,
      patientCreateModalCount: 0,
      patientEditModalCount: 0,
      searchTypes: [{ value: 1, name: "Passport Number" }],
      searchType: null,
      name: "",
      username: "",
      passport_number: "",
    };
  },
  mixins: [util],
  created() {
    this.searchType = this.searchTypes[0];
  },
  async mounted() {
    await this.fetchPaginatedData();
  },
  methods: {
    ...mapActions({
      getPatients: "appData/getPatients",
      deletePatient: "appData/deletePatient",
    }),
    async filter() {
      this.currentPage = 1;
      await this.fetchPaginatedData();
    },
    async search() {
      this.currentPage = 1;
      await this.fetchPaginatedData();
    },
    async fetchPaginatedData() {
      try {
        let tempParams = {
          pageNumber: this.currentPage,
          passport_number: this.passport_number,
        };
        const res = await this.getPatients(tempParams);
        this.patients = res.data.results;
        console.log(this.patients);
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },
    createPatient() {
      this.patientCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("patient-create-modal");
      });
    },
    editPatient(patient) {
      this.patient = patient;
      this.patientEditModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("patient-edit-modal");
      });
    },
    async removePatient(patient) {
      this.patient = patient;

      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const res = await this.deletePatient({
              pk: this.patient.id,
            });
            if (res.status === 200) {
              this.$swal({
                title: "Patient deleted successfully",
                icon: "success",
              });
              if (
                (this.totalItems - 1) % this.perPage === 0 &&
                this.currentPage !== 1
              ) {
                this.currentPage -= 1;
              } else {
                await this.fetchPaginatedData();
              }
            }
          } catch (error) {
            this.show = false;
            this.displayError(error);
          }
        } else {
          this.show = false;
        }
      });
    },
    async onModalClosed() {
      await this.fetchPaginatedData();
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
    }),
    rows() {
      return this.patients.length;
    },
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
};
</script>

<style>
.bg1 {
  color: red;
  font-weight: normal;
}
</style>
